import React, { Fragment } from 'react'
import { Trans } from "react-i18next"
import "../Features/i18n"

export default function FeatureArea() {

  return (
    <Fragment>
          <div className="ltn__our-history-area section-bg-0 mt-120 pt-120 pb-25">
              <div className="container">
                  <div className="ltn__feature-area mt-120 mt--150 section-bg-0">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="ltn__feature-item-box-wrap bg-white">
                                      <div className="row  justify-content-center">
                                          <div className="col-lg-4 col-md-6 col-12">
                                              <div className="ltn__feature-item ltn__feature-item-8">
                                                  <div className="ltn__feature-icon">
                                                      <img src="assets/img/icons/svg/5-madel.svg" alt="#" />
                                                  </div>
                                                  <Trans i18nKey="featureOne">
                                                      <div className="ltn__feature-info">
                                                          <h4>Produits de Qualités</h4>
                                                          <p>Des ingrédients 100% Naturels</p>
                                                      </div>
                                                  </Trans>
                                              </div>
                                          </div>
                                          <div className="col-lg-4 col-md-6 col-12">
                                              <div className="ltn__feature-item ltn__feature-item-8">
                                                  <div className="ltn__feature-icon">
                                                      <img src="assets/img/icons/svg/2-track.svg" alt="#" />
                                                  </div>
                                                  <Trans i18nKey="featureTwo">
                                                      <div className="ltn__feature-info">
                                                          <h4>Livraison 58 Wilayas</h4>
                                                          <p>A domicile</p>
                                                      </div>
                                                  </Trans>
                                              </div>
                                          </div>
                                          <div className="col-lg-4 col-md-6 col-12">
                                              <div className="ltn__feature-item ltn__feature-item-8">
                                                  <div className="ltn__feature-icon">
                                                      <img
                                                          src="https://img.icons8.com/ios/50/000000/full-tool-storage-box-.png"
                                                          alt="storage-box"
                                                      />
                                                  </div>
                                                  <Trans i18nKey="featureThree">
                                                      <div className="ltn__feature-info">
                                                          <h4>Fabrication Artisanale</h4>
                                                          <p>De la conception à la production</p>
                                                      </div>
                                                  </Trans>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </Fragment>
  )
}
