import React, { Fragment } from "react";
import dateFormat from "dateformat";
//import { useTranslation } from "react-i18next"
import FeatureArea from "../components/FeatureArea";
import "../Features/i18n"





export default function Posts(props) {
    //Translation
    //const { t } = useTranslation();

    //Posts data
    const { posts } = props;
    if (!posts || posts.length === 0) return (<Fragment><div className="spin"></div> </Fragment> )

    return (
        <Fragment>
        {/* <!-- BREADCRUMB AREA START --> */}
        {/* <div
            className="ltn__breadcrumb-area ltn__breadcrumb-area-4 bg-overlay-theme-10--- bg-image-bread"
            data-bg="img/bg/4.png">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-4 justify-content-between">
                    <div className="section-title-area">
                        <h1 className="section-title white-color">{t("Life Style")}</h1>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div> */}
        {/* <!-- BREAxport DCRUMB AREA END --> */}

        {/* <!-- BLOG AREA START --> */}
        <div className="ltn__blog-area mb-120 mt-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 lg-2">
                        <div className="row">
                            {/* <!-- Blog Item --> */}
                            {posts.map((post) => {
                                return (
                                    <div key={post.id} className="col-sm-6 col-12">
                                        <div className="ltn__blog-item ltn__blog-item-7">
                                            <div className="ltn__blog-img">
                                                <a href={post.slug}><img src={post.image} alt="1" /></a>
                                            </div>
                                            <div className="ltn__blog-brief">
                                                <div className="ltn__blog-meta">
                                                    <ul>
                                                        <li className="ltn__blog-author">
                                                            <a href="2">{post.category}</a>
                                                        </li>
                                                        <li>
                                                            <span>{dateFormat(post.published, "mmmm dS, yyyy")}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <h3 className="ltn__blog-title"><a href={post.slug}>{post.title}</a></h3>
                                                <p>{post.excerpt.substr(0, 75)}....</p>
                                                <div className="ltn__blog-btn">
                                                    <a href={post.slug}>Read more <i className="fas fa-angle-double-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
                            {/* <!-- Author Widget (2) --> */}
                            {/* <div className="widget ltn__author-widget ltn__author-widget-2">
                                <h4 className="ltn__widget-title">OZACLES</h4>
                                <div className="ltn__author-widget-inner">
                                    <img src="https://images.unsplash.com/photo-1591102972305-213abaa76d6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80" alt="Ima"/>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis distinctio, odio, eligendi suscipit reprehenderit atque.</p>
                                    <div className="ltn__social-media">
                                        <ul>
                                            <li><a href="1" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="2" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="3" title="Linkedin"><i className="fab fa-linkedin"></i></a></li>                                        
                                            <li><a href="5" title="Youtube"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!-- Search Widget --> */}
                            <div className="widget ltn__search-widget">
                                <form action="/search/">
                                    <input type="text" name="search" placeholder="Search your keyword..."/>
                                    <button type="submit"><i className="fas fa-search"></i></button>
                                </form>
                            </div>



                            {/* <!-- Menu Widget (Category) --> */}
                            {/* <div className="widget ltn__menu-widget">
                                <h4 className="ltn__widget-title">Categories</h4>
                                <ul>
                                    <li><a href="category/1">Beard Oils</a></li>
                                    <li><a href="category/2"> Beard Balms</a></li>
                                    <li><a href="category/3">Shampoos</a></li>
                                    <li><a href="category/4">Men Soap</a></li>
                                    <li><a href="categroy/5">beard box</a></li>
                                </ul>
                            </div> */}
                            {/* <!-- Social Media Widget --> */}
                            <div className="widget ltn__social-media-widget">
                                <div className="ltn__social-media-2 text-center">
                                    <ul>
                                        <li><a href="https://www.facebook.com/ozac.care" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/ozac/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
                                        <li><a href="https://www.instagram.com/ozac.care/" target="_blank" rel="noopener noreferrer" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>

                        </aside>
                    </div>
                </div>
            </div>
        </div>
        <FeatureArea />

        </Fragment>

    )
    };