import React, {useEffect, useRef} from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"



const MobileNavigation = (props) => {

    const { t } = useTranslation();
    /*Close Menu Outside */
    let menuRef = useRef(0);
    useEffect(() => { 
        let handler = (event)=> {
            if(!menuRef.current.contains(event.target)){
                props.closeMobileMenu(false);
            }
        }
        document.addEventListener("mousedown", handler);
        return() => {
            document.removeEventListener("mousedown",handler)
        }
    },);



    return (
        <div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu ltn__utilize-open" >
            <div ref={menuRef} className="ltn__utilize-menu-inner ltn__scrollbar">
                <div className="ltn__utilize-menu-head">
                    <div className="site-logo">
                        <a href="#page-top">
                            <img src="assets/img/logoz.png" alt="Logo" />
                        </a>
                    </div>
                    <button onClick={()=> props.isMobile && props.closeMobileMenu() } className="ltn__utilize-close">x</button>
                </div>
                <div className="ltn__utilize-menu-search-form">
                    <form action="/search/">
                        <input type="text" placeholder="Search..." />
                        <button>
                            <i className="fas fa-search"></i>
                        </button>
                    </form>
                </div>
                <div className="ltn__utilize-menu">
                    <ul>
                        <li onClick={()=> props.isMobile && props.closeMobileMenu() }>
                            <NavLink to="/" spy="true" smooth="true" offset={10} duration={500}  className="js-scroll-trigger cursor">
                            {t("Accueil")}
                            </NavLink>
                        </li>
                        <li onClick={()=> props.isMobile && props.closeMobileMenu() }>
                            <NavLink to="products" spy="true" smooth="true" offset={10} duration={500} className="js-scroll-trigger cursor" >
                          {t("Produits")}
                            </NavLink>
                        </li>
                        <li onClick={()=> props.isMobile && props.closeMobileMenu() }>
                        <NavLink to="blog" spy="true" smooth="true" offset={-120} duration={500} className="js-scroll-trigger cursor life-style">
                        {t("Life Style")}
                        </NavLink>
                        </li>
                        <li onClick={()=> props.isMobile && props.closeMobileMenu() }>
                            <NavLink to="about" spy="true" smooth="true" offset={-120} duration={500} className="js-scroll-trigger cursor">
                          {t("A propos")}
                            </NavLink>
                        </li>
                        <li onClick={()=> props.isMobile && props.closeMobileMenu() }>
                            <NavLink to="contact" spy="true" smooth="true" offset={-80} duration={500} className="js-scroll-trigger cursor">
                          {t("Contact")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="ltn__social-media-2">
                    <ul>
                        <li>
                            <a
                                href="https://www.facebook.com/ozac.care"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Facebook" >
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/ozac/"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Linkedin">
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/ozac.care/"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Instagram">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
    );
}

export default MobileNavigation;