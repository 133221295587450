import React, {useEffect, useState} from "react";
import mainAxiosInstance from "./Axios";
import Posts from './pages/Posts';
import PostLoadingComponent from './Features/Blog/PostLoading';

export default function BlogData() {
    const PostLoading = PostLoadingComponent(Posts);
    const [appState, setAppState] = useState({
        loading: false,
        posts: null,
    });
    // const [data, setData] = useState({ allPosts: [] });
	useEffect(() => {
		mainAxiosInstance.get().then((res) => {
			setAppState({ loading: false, posts: res.data });
			// console.log("BlogData",res.data);
		});
	}, [setAppState]);
    return(
        <div className="postData">
            <PostLoading isLoading={appState.loading} posts={appState.posts} />
        </div>
    )
}