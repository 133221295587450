import React,{ useState, useEffect, Fragment  } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../Axios';
import dateFormat from "dateformat"
import FeatureArea from '../components/FeatureArea';


export default function SinglePost() {
	const { slug } = useParams();

	const [data, setData] = useState({ posts: [] });

	useEffect(() => {
		axiosInstance.get(slug).then((res) => {
			setData({ posts: res.data });
			console.log(res.data);
		});
	}, [slug]);

  return (
            <Fragment>
                <div className="ltn__page-details-area ltn__blog-details-area mb-120 mt-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                {/* <img src={data.posts.image} alt="Ima"/> */}

                                <div className="ltn__blog-details-wrap">
                                                <div className="ltn__page-details-inner ltn__blog-details-inner">
                                                    <h1 className="ltn__blog-title">{data.posts.title}</h1>
                                                    <div className="ltn__blog-meta">
                                                        <ul>
                                                            <li className="ltn__blog-date mb-20">
                                                                <i className="far fa-calendar-alt"></i>{dateFormat(data.posts.published, "mmmm dS, yyyy")}
                                                            </li>
                                                        </ul>
                                                        <img src={data.posts.image} alt="Ima"/>
                                                    </div>
                                                     <div dangerouslySetInnerHTML={{__html: data.posts.content}}></div>
                                                    
                                                    <hr/>
                                                </div>
                                    {/* blog-tags-social-media */}
                                    <div className="ltn__blog-tags-social-media mt-80 row">
                                        <div className="ltn__tagcloud-widget col-lg-8">
                                            {/* <h4>Releted Tags</h4>
                                            <ul>
                                                <li>
                                                    <a href="1">Popular</a>
                                                </li>
                                                <li>
                                                    <a href="1">Business</a>
                                                </li>
                                                <li>
                                                    <a href="1">ux</a>
                                                </li>
                                            </ul> */}
                                        </div>
                                        <div className="ltn__social-media text-right col-lg-4">
                                            <h4>Share</h4>
                                            <ul>
                                              <li><a href="https://www.facebook.com/ozac.care" target="_blank"  rel="noopener noreferrer" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                              <li><a href="https://www.linkedin.com/company/ozac/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
                                              <li><a href="https://www.instagram.com/ozac.care/" target="_blank" rel="noopener noreferrer" title="Instagram"><i className="fab fa-instagram"></i></a></li>                                 
                                            </ul>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
                                    {/* <!-- Author Widget (2) --> */}
                                    <div className="widget ltn__author-widget ltn__author-widget-2">
                                        <h4 className="ltn__widget-title">OZACLES</h4>
                                        <div className="ltn__author-widget-inner">
                                            <img src="https://images.unsplash.com/photo-1591102972305-213abaa76d6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80" alt="Ima"/>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis distinctio, odio, eligendi suscipit reprehenderit atque.</p>
                                            <div className="ltn__social-media text-center">
                                            <ul>
                                              <li><a href="https://www.facebook.com/ozac.care" target="_blank"  rel="noopener noreferrer" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                              <li><a href="https://www.linkedin.com/company/ozac/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
                                              <li><a href="https://www.instagram.com/ozac.care/" target="_blank" rel="noopener noreferrer" title="Instagram"><i className="fab fa-instagram"></i></a></li>                                 
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Search Widget --> */}
                                    <div className="widget ltn__search-widget">
                                        <form action="/search/">
                                            <input type="text" name="search" placeholder="Search your keyword..."/>
                                            <button type="submit"><i className="fas fa-search"></i></button>
                                        </form>
                                    </div>

                                    {/* <!-- Menu Widget (Category) --> 
                                    <div className="widget ltn__menu-widget">
                                        <h4 className="ltn__widget-title">Categories</h4>
                                        <ul>
                                            <li><a href="1">Business</a></li>
                                            <li><a href="1">Consultant </a></li>
                                            <li><a href="1">Creative </a></li>
                                            <li><a href="1">UI/UX </a></li>
                                            <li><a href="1">Technology </a></li>
                                        </ul>
                                    </div>
                                     <!-- Social Media Widget --> */}
                                    <div className="widget ltn__social-media-widget">
                                      <div className="ltn__social-media-2 text-center">
                                          <ul>
                                              <li><a href="https://www.facebook.com/ozac.care" target="_blank"  rel="noopener noreferrer" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                              <li><a href="https://www.linkedin.com/company/ozac/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
                                              <li><a href="https://www.instagram.com/ozac.care/" target="_blank" rel="noopener noreferrer" title="Instagram"><i className="fab fa-instagram"></i></a></li>                                 
                                          </ul>
                                      </div>
                                  </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <FeatureArea/>
            </Fragment>

  )
}
