import axios from 'axios'
import axiosRetry from 'axios-retry';

const mainAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_mainURL,
	timeout: 5000,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});
// Axios Retry Package: intercepts requests or responses before they are handled by then or catch
axiosRetry(mainAxiosInstance, 
	{ 	retries: 3, // number of retries
		retryDelay: (retryCount) => {
			//console.log(`retry attempt: ${retryCount}`);
			return retryCount * 5000; // time interval between retries
		},
		retryCondition: (error) => {
			// if retry condition is not specified, by default idempotent requests are retried
			return error
		},
 });
//console.log(axiosRetry)

const searchAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_searchURL,
	timeout: 5000,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

const adminAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_adminURL,
	timeout: 5000,
	headers: {
		'Content-Type': 'multipart/form-data',
		accept: 'application/json',
	},
});



export default mainAxiosInstance	
export {adminAxiosInstance, mainAxiosInstance, searchAxiosInstance};