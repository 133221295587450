import React, { Fragment } from 'react'
import { Trans, useTranslation } from "react-i18next"
import FeatureArea from '../components/FeatureArea';



export default function Products() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="ltn__breadcrumb-area ltn__breadcrumb-area-4 bg-overlay-theme-10--- bg-image-bread mb-120">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-4 justify-content-between">
                          <div className="section-title-area">
                              <h1 className="section-title white-color">{t("Nos Produits")}</h1>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div
        className="ltn__about-us-area pt-120 pb-120 section-bg-0 mb-50"
        id="products">
        <div className="container ">
          <div className="row" id="bluemoon">
            <div className="col-md-4 align-self-center">
              <div className="about-us-img-wrap ">
                <img
                  src="assets/img/banner/6.png"
                  alt="OZAC beard oil Blue moon"
                />
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="about-us-info-wrap pt-30 pb-30 pl-70">
                <h1>Blue Moon Beard Oil</h1>
                <Trans i18nKey="productOne">
                  <p>
                    L'huile à barbe Blue Moon, spécialement conçue pour
                    hydrater et apaiser les démangeaisons. Cette huile
                    nourrit, adoucit et hydrate votre peau et votre poil,
                    rendant votre barbe très douce, agréable au touché et
                    subtilement parfumée.
                  </p>
                </Trans>
                <Trans i18nKey="productOneWhy">
                  <p>
                    {" "}
                    <b>Sa particularité </b>
                    Hydrate et apaise les démangeaisons{" "}
                  </p>
                </Trans>
                <Trans i18nKey="productOneFor">
                  <p>
                    {" "}
                    <b>Type de barbe </b>
                    Adaptée à tout type de barbe{" "}
                  </p>
                </Trans>
                <Trans i18nKey="productOneSmell">
                  <p>
                    {" "}
                    <b>Senteur </b>
                    Une touche boisée avec un parfum citronné frais et
                    énergisant
                  </p>
                </Trans>
                <div className="btn-wrapper">
                  <button className="theme-btn-3 btn text-uppercase text-dark">
                    Jojoba Oil
                  </button>
                  <button className="theme-btn-3 btn text-uppercase text-dark">
                    Argan Oil
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="cedarwood">
            <div className="col-md-6 align-self-center">
              <div className="about-us-info-wrap pt-30 pb-30 pr-70">
                <h1>Cedar Wood Beard Oil</h1>
                <Trans i18nKey="productTwo">
                  <p>
                    L'huile à barbe Cedar Wood, spécialement conçue pour
                    hydrater et apaiser les démangeaisons. Cette huile
                    nourrit, adoucit et hydrate votre peau et votre poil,
                    rendant votre barbe très douce, agréable au touché et
                    subtilement parfumée.
                  </p>
                </Trans>
                <Trans i18nKey="productTwoWhy">
                  <p>
                    {" "}
                    <b>Sa particularité </b>
                    Hydrate et apaise les démangeaisons{" "}
                  </p>
                </Trans>
                <Trans i18nKey="productTwoFor">
                  <p>
                    {" "}
                    <b>Type de barbe </b>
                    Adaptée à tout type de barbe{" "}
                  </p>
                </Trans>
                <Trans i18nKey="productTwoSmell">
                  <p>
                    <b>Senteur </b>
                    une senteur boisée qui vous rappellera vos randonnées en
                    forêt.
                  </p>
                </Trans>
                <div className="btn-wrapper">
                  <button className="theme-btn-1 btn text-uppercase text-dark">
                    CEDAR OIL
                  </button>
                  <button className=" theme-btn-1 btn text-uppercase text-dark">
                    JOJOBA OIL
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 align-self-center">
              <div className="about-us-img-wrap">
                <img
                  src="assets/img/banner/5.png"
                  alt="OZAC beard oil Cedar Wood"
                />
              </div>
            </div>
          </div>

          <div className="row" id="greenbay">
            <div className="col-md-4 align-self-center">
              <div className="about-us-img-wrap mt-10">
                <img
                  src="assets/img/banner/7.png"
                  alt="OZAC beard oil Green Bay"
                />
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="about-us-info-wrap pt-30 pb-30 pl-70">
                <h1>Green Bay Beard Oil </h1>
                <Trans i18nKey="productThree">
                  <p>
                    Afin de prendre soin de votre barbe au quotidien, rien de
                    tel qu’une huile 100% naturelle qui nourrit, adoucit et
                    hydrate votre peau et votre poil, rendant votre barbe très
                    douce, agréable au touché et subtilement parfumé.
                  </p>
                </Trans>
                <Trans i18nKey="productThreeWhy">
                  <p>
                    {" "}
                    <b>Sa particularité </b>
                    Hydrate et favorise la pousse de votre barbe.
                  </p>
                </Trans>
                <Trans i18nKey="productThreeFor">
                  <p>
                    {" "}
                    <b>Type de barbe </b>
                    Adaptée à tout type de barbe{" "}
                  </p>
                </Trans>
                <Trans i18nKey="productThreeSmell">
                  <p>
                    <b>Senteur </b>
                    Une fraicheur intense alliée à une subtile touche citronné
                  </p>
                </Trans>
                <div className="btn-wrapper">
                  <button className="theme-btn-4 btn text-uppercase text-dark">
                    MINT OIL{" "}
                  </button>
                  <button className="theme-btn-4 btn text-uppercase text-dark">
                    ARGAN OIL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <FeatureArea/>
    </Fragment>
  )
}
