import React, { Fragment, useRef, useState} from 'react'
import { Trans, useTranslation } from "react-i18next"
import FeatureArea from '../components/FeatureArea'
import "../Features/i18n"
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser'

export default function Contact() {

  //i18n
  const { t } = useTranslation();

  //Status Message(Form Sent)
  const [statusMessage, setStatusMessage] = useState("message");

  //Hook Form
  const { register, handleSubmit, reset, formState: { errors },} = useForm();
  

  //react Email Js
  const form = useRef();
  const sendEmail = (form) => {
    const statusMessage = document.querySelector('.status-message')
    emailjs
      .sendForm('service_iop3utr', 'template_h7nqawp', "#contact-form" , 'od2nU1koEcLD80GRK')
      .then(
      (result) => {
      console.log(result.text);
      setStatusMessage(<div className="btn success">Message sent!</div>);
      statusMessage.className = "status-message";
      setTimeout(()=> {
        statusMessage.className = 'status-message'
      }, 10000)
      },
      (err) => {
      console.log(err.text);
      setStatusMessage("Failed to send message! Please try again later.");
      statusMessage.className = "status-message failure";
      setTimeout(()=> {
        statusMessage.className = 'status-message'
      }, 5000)
      });
      reset();
  };

  return (      
    <Fragment>
                <div className="ltn__breadcrumb-area ltn__breadcrumb-area-4 bg-overlay-theme-10---  mb-0 bg-image-bread">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-4 justify-content-between">
                          <div className="section-title-area">
                            <Trans i18nKey="contactPage">
                              <h1 className="section-title white-color">Contact</h1>
                            </Trans>
                          </div>
                          <div className="ltn__breadcrumb-list white-color">
                            <ul>
                              <Trans i18nKey="contactInfo">
                                <li><p className="white-color"> La team <span className="text-warning">OZAC </span>  est à votre ecoute</p></li>
                              </Trans>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ltn__contact-message-area pt-115 pb-70">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8 col-md-7">
                        <div className="ltn__form-box contact-form-box-2 mb-50 box-shadow--- white-bg--">
                          <form ref={form} onSubmit={handleSubmit(sendEmail)} id="contact-form" method="post">
                            <p className='status-message'>{statusMessage}</p>
                            <div>
                              <input type="text" {...register("user_name",{required:true})} name="user_name" placeholder={t("Name")} />
                              <span>
                                {errors.user_name?.type === "required" && <div className="btn failure">Name is required</div>}
                              </span>
                            </div>
                            <div>
                            <input type="email" {...register("user_email",{required:true, pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i, })} name="user_email" placeholder={t("Email")} />
                              <span> 
                                {errors.user_email?.type === "required" && <div className="btn failure">Email is required</div>}
                                {errors.user_email?.type === "pattern" &&  <div className="btn failure">Entered email is in wrong format</div>}
                              </span>
                            </div>
                            <div>
                            <input type="text"  {...register("number", {minLength:6,maxLength: 13,})} name="number" placeholder={t("Phone")} />
                            <span>
                              {errors.number?.type === "minLength" &&  <div className="btn failure">Entered number is less than 6 digits</div>}
                              {errors.number?.type === "maxLength" && <div className="btn failure">Entered number is more than 13 digits</div>}
                            </span>
                            </div>
                            <textarea name="message" {...register("message",{required:true})} placeholder={t("Message")}/>
                            <span>
                                {errors.message?.type === "required" && <div className="btn failure">Message is required</div>}
                            </span>
                            <div className="btn-wrapper mt-0">
                              <Trans i18nKey="sendButton">
                              <button className="btn theme-btn-1 btn-effect-1 text-uppercase bg-warning mb-50" type="submit" value="Send">Send</button>
                              </Trans>
                            </div>
                            <p className="form-messege mb-0 mt-20"></p>
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-5">
                        <div className="ltn__contact-address-area pl-70">
                          <div className="ltn__contact-address-item-4">
                          <Trans i18nKey="contactEmail">
                            <h3>Email</h3>
                          </Trans>
                            <p>contact@ozac-care.com</p>
                          </div>

                          <div className="ltn__social-media mt-15">
                            <ul>
                              <li><a href="https://www.facebook.com/ozac.care" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="https://www.linkedin.com/company/ozac/" title="Linkedin"><i className="fab fa-linkedin fa-1x"></i></a></li>
                              <li><a href="https://www.instagram.com/ozac.care/" title="Instagram"><i className="fab fa-instagram fa-1x"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

        <FeatureArea/>
    </Fragment>
      );
}
