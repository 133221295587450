import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import React from 'react';
import Header from './components/Header';
import Home from './pages/Home';
import Products from './pages/Products';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import ScrollToTop from './Features/ScrollToTop';
import BlogData from './BlogData';
import SinglePost from './pages/SinglePost';
import Search from './Features/Blog/Search';
import Category from './Features/Blog/Category';





function App() {
  
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Header/>
          <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route excat path='products' element ={<Products/>} />
            <Route exact path='About' element ={<About/>} />
            <Route exact path='contact' element ={<Contact/>} />
            <Route path="*" element={<Navigate to="/" />} />
            {/* Blog */}
            <Route path='blog' element={<BlogData/>} />
            <Route path='/:slug' element={<SinglePost/>} />
            <Route path="/search" element ={<Search/>} />
            {/* <Route path="/category/:id" element ={<Category/>} /> */}
              
          </Routes>
        <Footer/> 
      </Router>
    </div>
  );
}

export default App;
