import React, { Suspense,useState} from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import "../Features/i18n"
import ArabicMenu from "../Features/ArabicMenu"
import MobileNavigation from "../Features/MobileNavigation"



export default function Header() {


    const [open, setOpen] = useState(false);
    const [show,setShow] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const closeMobileMenu = ()=> setOpen (false);


    /* Languages*/
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState("fr");
    const handleLangChange = evt => {
      const lang = evt.target.value;
      //console.log(lang);
      setLanguage(lang);
      i18n.changeLanguage(lang);
    };
  
  /* Sticky NavBar*/
    const stickyBar = () =>{
      if (window.scrollY >= 112.8){
        setNavbar(true)
      } else(
        setNavbar(false)
      );
    };
  
    window.addEventListener('scroll', stickyBar);

  return (
      <header className="ltn__header-area ltn__header-4 ltn__header-6 ltn__header-transparent--- gradient-color-2---">
          <div className={navbar ? "ltn__header-middle-area ltn__header-4 ltn__header-6 ltn__header-sticky ltn__sticky-bg-white sticky-active" : "ltn__header-middle-area ltn__header-4 ltn__header-6 ltn__header-sticky ltn__sticky-bg-white"}>
              <div className="container">
                  <div className="row">
                      <div className="col">
                          <div className="site-logo">
                              <a href="/">
                                  <img src="assets/img/logoz.png" alt="OZAC Logo" />
                              </a>
                          </div>
                      </div>
                      <div className="col header-menu-column">
                          <div className="header-menu d-none d-xl-block">
                              <nav>
                                  {show ? <ArabicMenu /> :

                                      <div className="ltn__main-menu">
                                          <ul>
                                              <li>
                                                  <NavLink to="/" spy="true" smooth="true"offset={10} duration={500} className="js-scroll-trigger cursor">
                                                      {t("Accueil")} 
                                                  </NavLink>
                                              </li>
                                              <li>
                                                  <NavLink to="products" spy="true" smooth="true"offset={10} duration={500} className="js-scroll-trigger cursor" >
                                                      {t("Produits")}
                                                  </NavLink>
                                              </li>
                                              <li>
                                                  <NavLink to="blog" spy="true" smooth="true"offset={-120} duration={500} className="js-scroll-trigger cursor theme-btn-1 text-uppercase text-dark">
                                                      {t("Life Style")}
                                                  </NavLink>
                                              </li>
                                              <li>
                                                  <NavLink to="about" spy="true" smooth="true"offset={-120} duration={500} className="js-scroll-trigger cursor">
                                                      {t("A propos")}
                                                  </NavLink>
                                              </li>
                                              <li>
                                                  <NavLink to="contact" spy="true" smooth="true"offset={-80} duration={500} className="js-scroll-trigger cursor">
                                                      {t("Contact")}
                                                  </NavLink>
                                              </li>
                                          </ul>
                                      </div>
                                  }

                              </nav>
                          </div>
                      </div>

                      <div className="col">
                          <div className="ltn__header-options">
                              <div className="ltn__social-media ">
                                  <ul>

                                      <li>
                                          <a
                                              href="https://www.facebook.com/ozac.care"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              title="Facebook">
                                              <i className="fab fa-facebook-f fa-1x"></i>
                                          </a>
                                      </li>
                                      <li>
                                          <a
                                              href="https://www.linkedin.com/company/ozac/"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              title="Linkedin">
                                              <i className="fab fa-linkedin fa-1x"></i>
                                          </a>
                                      </li>
                                      <li>
                                          <a
                                              href="https://www.instagram.com/ozac.care/"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              title="Instagram">
                                              <i className="fab fa-instagram fa-1x"></i>
                                          </a>
                                      </li>
                                      <li>
                                          <div>
                                              <Suspense fallback="loading">
                                                  <div >
                                                      <select className="dropdown_lang" onChange={handleLangChange} value={language}>
                                                          <option onClick={() => setShow(false)} value="fr">FR</option>
                                                          <option onClick={() => setShow(false)} value="en">EN</option>
                                                          <option onClick={() => setShow(true)} value="ar">AR</option>
                                                      </select>
                                                  </div>
                                              </Suspense>
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                              <div className="mobile-menu-toggle d-xl-none">
                                  <NavLink onClick={() => setOpen(!open)} className="ltn__utilize-toggle">
                                      <svg viewBox="0 0 800 600">
                                          <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                          <path d="M300,320 L540,320" id="middle"></path>
                                          <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "> </path>
                                      </svg>
                                  </NavLink>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {open && <MobileNavigation isMobile ={true} closeMobileMenu ={closeMobileMenu} />}
      </header>
  )
}
