import React, { Fragment} from "react"
import { Trans, useTranslation } from "react-i18next"
import FeatureArea from "../components/FeatureArea";
import "../Features/i18n"

export default function About() {

  const { t } = useTranslation();

  return (
    <Fragment>
      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-4 bg-overlay-theme-10--- bg-image-bread"
        data-bg="img/bg/4.png">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-4 justify-content-between">
                <div className="section-title-area">
                   <h1 className="section-title white-color">{t("Notre Histoire")}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__about-us-area pt-120-- pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 align-self-center">
              <div className="about-us-info-wrap pt-30 pb-30">
                <h1 className="mb-50">
                  OZAC <span className="text-warning">-</span> MEN{" "}
                  <span className="text-warning">|</span> CARE
                </h1>
                <Trans i18nKey="aboutUs">
                  <p>
                    OZAC tient son origine de OZACLES, roi de la Numidie, puise
                    son authenticité dans l’histoire berbère, ainsi que dans la
                    nature, les océans et les montagnes.
                  </p>

                  <p>
                    OZAC tient à proposer un changement en produisant des soins
                    cosmétiques naturels et bio composés de matières premières
                    de qualités, afin de prendre soin de la peau, mais aussi et
                    surtout de la barbe.
                  </p>
                  <p>
                    {" "}
                    Contrairement à la plupart des produits présents sur le
                    marché des cosmétiques, notre marque considère important
                    d’offrir des soins naturels mais également artisanaux et
                    fabriqués en Algérie.
                  </p>
                </Trans>
                   <div className="btn-wrapper animated">
                      <a
                        href="products"
                        className="btn btn-warning"
                      >
                        Decouvrez nos produits
                      </a>
                    </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 align-self-center">
              <div className="about-us-img-wrap about-img-right">
                <img src="assets/img/banner/oz1.jpg" alt="Banner" />
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <FeatureArea/>



    </Fragment>
  );
}
