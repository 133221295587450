import React from 'react'
import {useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"


const ArabicMenu = () => {
    const { t } = useTranslation();
    return(

            <div className="ltn__main-menu">
              <ul>
                <li>
                  <NavLink to="contact" spy={true} smooth={true} offset={10} duration={500}  className="js-scroll-trigger cursor">
                  {t("Contact")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="about" spy={true} smooth={true} offset={10} duration={500} className="js-scroll-trigger cursor" >
                    {t("A propos")}
                  </NavLink>
                </li>
                <li>
                <NavLink to="blog" spy={true} smooth={true} offset={-120} duration={500} className="js-scroll-trigger cursor theme-btn-1 text-uppercase text-dark">
                    {t("Life Style")}
                </NavLink>
                </li>
                <li>
                  <NavLink to="products" spy={true} smooth={true} offset={-120} duration={500} className="js-scroll-trigger cursor">
                  {t("Produits")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/" spy={true} smooth={true} offset={-80} duration={500} className="js-scroll-trigger cursor">
                    {t("Accueil")}
                  </NavLink>
                </li>
              </ul>
            </div>

    );
}

export default ArabicMenu;