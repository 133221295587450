import React, { Fragment,useState} from "react"
import { Link } from "react-scroll"
import { Trans, useTranslation } from "react-i18next"
import "../Features/i18n"





export default function Home() {
  

/* Translation*/ 
 const { t } = useTranslation();

 /*Scroll to Top */
 const [allup, setScrollup] = useState(false); 
 const scrollToTop = () =>{
  if (window.scrollY >= 500){
    setScrollup(true)
  } else(
    setScrollup(false)
  );
};

window.addEventListener('scroll', scrollToTop);



  return (
    <Fragment>
      <div className="body-wrapper">

        <div className="ltn__slider-area ltn__slider-6">
          <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
            <div
              className="ltn__slide-item section-bg-1 bg-image plr--2"
              data-bg="assets/img/slider/home.jpg"
            >
              <div className="ltn__slide-item-inner">
                <div className="container-fluid ">
                  <div className="row">
                    <div className="col-lg-9 col-md-7 col-sm-7 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner .ltn__slide-animation">
                          <Trans i18nKey="sliderTitle">
                            <h4 className="slide-sub-title animated text-white align-middle">
                              OZAC - une gamme de produits bio et naturelle
                            </h4>
                          </Trans>
                          <Trans i18nKey="sliderInfo">
                            <h1 className="slide-title animated text-white">
                              Une huile à barbe <br /> Naturelle pour appaiser{" "}
                              <br /> vos démangeaisons
                            </h1>
                            <div className="btn-wrapper animated">
                              <a
                                href="#products"
                                className="btn btn-round btn-white"
                              >
                                Decouvrez nos produits
                              </a>
                            </div>
                          </Trans>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__feature-area mt-120 mt--150 section-bg-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__feature-item-box-wrap bg-white">
                  <div className="row  justify-content-center">
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="ltn__feature-item ltn__feature-item-8">
                        <div className="ltn__feature-icon">
                          <img src="assets/img/icons/svg/5-madel.svg" alt="#" />
                        </div>
                        <Trans i18nKey="featureOne">
                          <div className="ltn__feature-info">
                            <h4>Produits de Qualités</h4>
                            <p>Des ingrédients 100% Naturels</p>
                          </div>
                        </Trans>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="ltn__feature-item ltn__feature-item-8">
                        <div className="ltn__feature-icon">
                          <img src="assets/img/icons/svg/2-track.svg" alt="#" />
                        </div>
                        <Trans i18nKey="featureTwo">
                          <div className="ltn__feature-info">
                            <h4>Livraison 58 Wilayas</h4>
                            <p>A domicile</p>
                          </div>
                        </Trans>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="ltn__feature-item ltn__feature-item-8">
                        <div className="ltn__feature-icon">
                          <img
                            src="https://img.icons8.com/ios/50/000000/full-tool-storage-box-.png"
                            alt="storage-box"
                          />
                        </div>
                        <Trans i18nKey="featureThree">
                          <div className="ltn__feature-info">
                            <h4>Fabrication Artisanale</h4>
                            <p>De la conception à la production</p>
                          </div>
                        </Trans>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ltn__about-us-area pt-120 pb-120 section-bg-1"
          id="products">
          <div className="container ">
            <div className="row" id="bluemoon">
              <div className="col-md-4 align-self-center">
                <div className="about-us-img-wrap ">
                  <img
                    src="assets/img/banner/6.png"
                    alt="OZAC beard oil Blue moon"
                  />
                </div>
              </div>
              <div className="col-md-6 align-self-center">
                <div className="about-us-info-wrap pt-30 pb-30 pl-70">
                  <h1>Blue Moon Beard Oil</h1>
                  <Trans i18nKey="productOne">
                    <p>
                      L'huile à barbe Blue Moon, spécialement conçue pour
                      hydrater et apaiser les démangeaisons. Cette huile
                      nourrit, adoucit et hydrate votre peau et votre poil,
                      rendant votre barbe très douce, agréable au touché et
                      subtilement parfumée.
                    </p>
                  </Trans>
                  <Trans i18nKey="productOneWhy">
                    <p>
                      {" "}
                      <b>Sa particularité </b>
                      Hydrate et apaise les démangeaisons{" "}
                    </p>
                  </Trans>
                  <Trans i18nKey="productOneFor">
                    <p>
                      {" "}
                      <b>Type de barbe </b>
                      Adaptée à tout type de barbe{" "}
                    </p>
                  </Trans>
                  <Trans i18nKey="productOneSmell">
                    <p>
                      {" "}
                      <b>Senteur</b>
                      Une touche boisée avec un parfum citronné frais et
                      énergisant
                    </p>
                  </Trans>
                  <div className="btn-wrapper">
                    <button className="theme-btn-3 btn text-uppercase text-dark">
                      Jojoba Oil
                    </button>
                    <button className="theme-btn-3 btn text-uppercase text-dark">
                      Argan Oil
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" id="cedarwood">
              <div className="col-md-6 align-self-center">
                <div className="about-us-info-wrap pt-30 pb-30 pr-70">
                  <h1>Cedar Wood Beard Oil</h1>
                  <Trans i18nKey="productTwo">
                    <p>
                      L'huile à barbe Cedar Wood, spécialement conçue pour
                      hydrater et apaiser les démangeaisons. Cette huile
                      nourrit, adoucit et hydrate votre peau et votre poil,
                      rendant votre barbe très douce, agréable au touché et
                      subtilement parfumée.
                    </p>
                  </Trans>
                  <Trans i18nKey="productTwoWhy">
                    <p>
                      {" "}
                      <b>Sa particularité </b>
                      Hydrate et apaise les démangeaisons{" "}
                    </p>
                  </Trans>
                  <Trans i18nKey="productTwoFor">
                    <p>
                      {" "}
                      <b>Type de barbe </b>
                      Adaptée à tout type de barbe{" "}
                    </p>
                  </Trans>
                  <Trans i18nKey="productTwoSmell">
                    <p>
                      <b>Senteur </b>
                      une senteur boisée qui vous rappellera vos randonnées en
                      forêt.
                    </p>
                  </Trans>
                  <div className="btn-wrapper">
                    <button className="theme-btn-1 btn text-uppercase text-dark">
                      CEDAR OIL
                    </button>
                    <button className=" theme-btn-1 btn text-uppercase text-dark">
                      JOJOBA OIL
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 align-self-center">
                <div className="about-us-img-wrap">
                  <img
                    src="assets/img/banner/5.png"
                    alt="OZAC beard oil Cedar Wood"
                  />
                </div>
              </div>
            </div>

            <div className="row" id="greenbay">
              <div className="col-md-4 align-self-center">
                <div className="about-us-img-wrap mt-10">
                  <img
                    src="assets/img/banner/7.png"
                    alt="OZAC beard oil Green Bay"
                  />
                </div>
              </div>
              <div className="col-md-6 align-self-center">
                <div className="about-us-info-wrap pt-30 pb-30 pl-70">
                  <h1>Green Bay Beard Oil </h1>
                  <Trans i18nKey="productThree">
                    <p>
                      Afin de prendre soin de votre barbe au quotidien, rien de
                      tel qu’une huile 100% naturelle qui nourrit, adoucit et
                      hydrate votre peau et votre poil, rendant votre barbe très
                      douce, agréable au touché et subtilement parfumé.
                    </p>
                  </Trans>
                  <Trans i18nKey="productThreeWhy">
                    <p>
                      {" "}
                      <b>Sa particularité </b>
                      Hydrate et favorise la pousse de votre barbe.
                    </p>
                  </Trans>
                  <Trans i18nKey="productThreeFor">
                    <p>
                      {" "}
                      <b>Type de barbe </b>
                      Adaptée à tout type de barbe{" "}
                    </p>
                  </Trans>
                  <Trans i18nKey="productThreeSmell">
                    <p>
                      <b>Senteur </b>
                      Une fraicheur intense alliée à une subtile touche citronné
                    </p>
                  </Trans>
                  <div className="btn-wrapper">
                    <button className="theme-btn-4 btn text-uppercase text-dark">
                      MINT OIL{" "}
                    </button>
                    <button className="theme-btn-4 btn text-uppercase text-dark">
                      ARGAN OIL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ltn__about-us-area pt-150-- pb-120 section-bg-0 mt-100"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 align-self-center">
                <div className="about-us-info-wrap pt-30 pb-30">
                  <h1 className="mb-50">
                    OZAC <span className="text-warning">-</span> MEN{" "}
                    <span className="text-warning">|</span> CARE
                  </h1>
                  <Trans i18nKey="aboutUs">
                    <p>
                      OZAC tient son origine de OZACLES, roi de la Numidie,
                      puise son authenticité dans l’histoire berbère, ainsi que
                      dans la nature, les océans et les montagnes.
                    </p>

                    <p>
                      OZAC tient à proposer un changement en produisant des
                      soins cosmétiques naturels et bio composés de matières
                      premières de qualités, afin de prendre soin de la peau,
                      mais aussi et surtout de la barbe.
                    </p>
                    <p>
                      {" "}
                      Contrairement à la plupart des produits présents sur le
                      marché des cosmétiques, notre marque considère important
                      d’offrir des soins naturels mais également artisanaux et
                      fabriqués en Algérie.
                    </p>
                  </Trans>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 align-self-center">
                <div className="about-us-img-wrap about-img-right">
                  <img
                    src="assets/img/banner/ozac.png"
                    alt="OZAC Beard Oil- Huile à barbe"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ltn__newsletter-area  pt-120-- pb-180 section-bg-1 "
          id="contact"
        >
          <div className="container">
            <div className="row ">
              <div className="col-lg-8 offset-lg-2">
                <div className="ltn__newsletter-inner text-center">
                  <Trans i18nKey="contactUs">
                    <h2 className="text-black mt-150">CONTACTEZ NOUS</h2>
                  </Trans>
                  <Trans i18nKey="contactUsInfo">
                    <p className="">
                      Vous pouvez envoyer vos messages directement à l'équipe
                      OZAC via
                    </p>
                  </Trans>
                  <a
                    href="mailto:contact@ozac-care.com"
                    className="theme-btn-1 btn text-uppercase text-dark mb-10"
                  >
                    contact@ozac-care.com
                  </a>
                  <p className="font-weight-bold text-muted">{t("Ou")}</p>
                  <div className="ltn__social-media mt-20">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/ozac.care"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Facebook"
                        >
                          <i className="fab fa-facebook-f fa-2x"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/ozac/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Linkedin"
                        >
                          <i className="fab fa-linkedin fa-2x"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/ozac.care/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Instagram"
                        >
                          <i className="fab fa-instagram fa-2x"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {allup ? (
          <Link
            id="scrollUp"
            style={{ position: "fixed", zIndex: "2147483647" }}
            to="page-top"
            spy={true}
            smooth={true}
            duration={600}
          >
            <i className="fa fa-angle-up cursor"></i>
          </Link>
        ) : (
          <a
            id="scrollUp"
            href="#page-top"
            style={{ position: "fixed", zIndex: "2147483647", display: "none" }}
          >
            <i className="fa fa-angle-up"></i>
          </a>
        )}
      </div>
    </Fragment>
  );
}
