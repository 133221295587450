import React from 'react'
import { NavLink } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import "../Features/i18n"

export default function Footer() {
    const { t } = useTranslation();
  return (
    <footer className="ltn__footer-area  section-bg-0">
    <div className="footer-top-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="footer-widget footer-about-widget">
              <div className="footer-logo">
                <div className="site-logo">
                  <img src="assets/img/logoz.png" alt="Logo" />
                </div>
              </div>
              <Trans i18nKey="footerInfo">
              <p>
                Des produits cosmétiques naturels et bio pour les
                hommes.
              </p>
              </Trans>
              <div className="footer-address">
                <ul>
                  <li>
                    <div className="footer-address-icon">
                      <i className="icon-mail"></i>
                    </div>
                    <div className="footer-address-info">
                      <p>
                        <a href="mailto:contact@ozac-care.com">
                          contact@ozac-care.com
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="ltn__social-media mt-20">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/ozac.care"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Facebook"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/ozac/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/ozac.care/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Instagram"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-sm-6 col-12">
            <div className="footer-widget footer-menu-widget clearfix">
              <h4 className="footer-title">{t("Accueil")}</h4>
              <div className="footer-menu cursor">
                <ul>
                  <li>
                    <NavLink to="products" spy="true" smooth="true" offset={10} duration={500}>{t("Produits")}</NavLink>
                  </li>
                  <li>
                    <NavLink to="about" spy="true" smooth="true" offset={10} duration={500}>{t("A propos")}</NavLink>
                  </li>
                  <li>
                    <NavLink to="contact" spy="true" smooth="true" offset={10} duration={500}>{t("Contact")}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-sm-6 col-12">
            <div className="footer-widget footer-menu-widget clearfix">
              <h4 className="footer-title">{t("Produits")}</h4>
              <div className="footer-menu">
                <ul>
                  <li>
                    <NavLink className="cursor" to="bluemoon" spy="true" smooth="true" offset={10} duration={500}>
                      Blue Moon
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="cursor" to="cedarwood" spy="true" smooth="true" offset={10} duration={500}>
                      Cedar Wood
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="cursor" to="greenbay" spy="true" smooth="true" offset={10} duration={500}>
                      Green Bay
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-8 col-sm-6 col-12">
            <div className="footer-widget footer-menu-widget clearfix">
              <h4 className="footer-title">{t("Contact")}</h4>
              <div className="footer-menu">
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-mail"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:contact@eozac-care.com">
                            contact@ozac-care.com
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ltn__copyright-area ltn__copyright-2 section-bg-2">
      <div className="container ltn__border-top">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="ltn__copyright-design clearfix">
              <p>
                All Rights Reserved - OZAC - {new Date().getFullYear()}{" "}
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12 align-self-center">
            <div className="ltn__copyright-menu text-right">
              <ul>
                <li>Terms & Conditions</li>
                <li>Privacy & Policy </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}
